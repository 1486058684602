import React, { useEffect, useState, useRef } from "react"
import TinderCard from "./ReactTinderCard"
import listings from "./listings.json"
import { ReactComponent as CrossIcon } from "../assets/cross.svg"
import { ReactComponent as HeartIcon } from "../assets/heart.svg"
import { ReactComponent as ShareIcon } from "../assets/share.svg"
import CircleButton from "./Button/CircleButton"
import PropertyCard from "./PropertyCard"
import Navbar from "./Navbar"
import Modal from "./Modal/Modal"
import { useSearchParams } from "react-router-dom"
import Div100vh from "react-div-100vh"
import Toast from "./Toast"
import QrcodeInfoBox from "./QrcodeInfoBox"
import Overlay from "./Overlay"
import ChatBubble from "./ChatBubble"
import { shareProperty } from "../utils/share"
import SearchBlankState from "./SearchBlankState"
import LoadingSpinner from "./LoadingSpinner"
import {
  appendOverwriteLocalStorage,
  getLocalStorage,
  storeLocalStorage,
} from "../utils/localStorageHelper"

const IconInfoGroup = ({ Icon, info }) => (
  <div className="flex flex-col space-y-1 items-center">
    <Icon width="24px" height="24px" />
    <p className="font-semibold">{info}</p>
  </div>
)

function PropertySwiper() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [rawPropertyData, setRawPropertyData] = useState([])
  const [swipeCounter, setSwipeCounter] = useState(0)
  const [modalState, setModalState] = useState(null)

  const [swipedId, setSwipedId] = useState(null)

  const [showToast, setShowToast] = useState(false)
  const [displayProperties, setDisplayProperties] = useState([])

  const [hasInitialized, setHasInitialized] = useState(false)

  const swipeRef = useRef()

  const setup = async () => {
    const location = searchParams.get("location")

    const rawImportedPropertyData = (
      await import(`../data/locations/${location ?? "london"}.json`)
    ).default

    const rawPropertyDataGuarded =
      rawPropertyData.length < 1
        ? Object.keys(rawImportedPropertyData).map(
            (k) => rawImportedPropertyData[k]
          )
        : rawPropertyData

    if (displayProperties.length > 1) setSwipeCounter((sc) => sc + 1)

    if (displayProperties.length > 0) {
      if (rawPropertyDataGuarded[swipeCounter + 5]) {
        console.log("hello", rawPropertyDataGuarded)
        setDisplayProperties((p) => [
          rawPropertyDataGuarded[swipeCounter + 5],
          ...p,
        ])
      }
    } else {
      const seenPropertiesFromLocal = getLocalStorage("seenProperties")

      const propertyDataWithoutSeen =
        seenPropertiesFromLocal.length > 0
          ? rawPropertyDataGuarded.filter(
              (p) => !seenPropertiesFromLocal.includes(p.id)
            )
          : rawPropertyDataGuarded

      const start = searchParams.get("start")

      const startProperty = rawImportedPropertyData[start]
      console.log("START", startProperty)

      if (startProperty) {
        console.log("CODE PATH")
        const propertyDataWithoutSeenAndStart = propertyDataWithoutSeen.filter(
          (p) => p.id !== startProperty.id
        )

        setRawPropertyData([startProperty, ...propertyDataWithoutSeenAndStart])
        setDisplayProperties([
          ...propertyDataWithoutSeenAndStart.slice(0, 4),
          startProperty,
        ])
      } else {
        setDisplayProperties(propertyDataWithoutSeen.slice(0, 5))
        setRawPropertyData(propertyDataWithoutSeen)
      }

      setHasInitialized(true)
    }
  }

  useEffect(() => {
    setup()
  }, [swipedId])

  const swiped = (direction, id) => {
    setSwipedId((prevId) => (prevId !== id ? id : prevId))
  }

  const onCardLeftScreen = (id, dir) => {
    appendOverwriteLocalStorage("seenProperties", id)
    const likedProperties = getLocalStorage("likedProperties")
    if (dir === "left") {
      if (likedProperties.includes(id)) {
        storeLocalStorage(
          "likedProperties",
          likedProperties.filter((p) => p !== id)
        )
      }
    } else if (dir === "right") {
      storeLocalStorage("likedProperties", [...likedProperties, id])
      // store this location as liked
      console.log("foo", searchParams.get("location") ?? "london")
      appendOverwriteLocalStorage(
        "likedLocations",
        searchParams.get("location") ?? "london"
      )
    }
  }

  const swipe = async (dir) => {
    await swipeRef.current.swipe(dir) // Swipe the card!
  }

  const cardWidth = "w-[80vw] md:w-[40vw] lg:w-[35vw] xl:w-[30vw] 2xl:w-[20vw]"

  const tinderCardClassName = `absolute ${cardWidth}`

  const topCardIndex = displayProperties.length - 1 - swipeCounter

  return (
    <>
      <ChatBubble classNames="hidden" />
      <QrcodeInfoBox />
      <Div100vh>
        <div className=" w-screen h-full overflow-hidden  bg-white ">
          {/* <div className=" w-screen h-full overflow-hidden  bg-white "> */}
          {/* <div className=" w-screen h-full overflow-hidden  bg-repeat bg-[url('../public/bg-houses.png')] bg-gray-300 bg-blend-multiply "> */}
          root
          <div className="w-full h-full pb-16 xl:pb-20 bg-red-50 bg-gradient-to-b from-[#FD297B]/40 via-[#FF5864]/40 to-[#FF655B]/40  flex-col flex items-center justify-center  ">
            <Navbar />
            <div className="flex-1 flex-col flex items-center justify-center w-full  ">
              {/* .cardcontainer */}

              {/* {console.log("dp", displayProperties)} */}
              {!hasInitialized && (
                // <p className="animate-spin text-white text-5xl">🏠</p>
                <LoadingSpinner />
              )}
              {hasInitialized &&
                (displayProperties.length <= swipeCounter ||
                  rawPropertyData.length < 1) && (
                  <>
                    <SearchBlankState />
                  </>
                )}
              <div
                className={`bg-orange-500 relative ${cardWidth} max-h-[450px] flex items-center justify-center`}
              >
                {displayProperties.map((property, i) => {
                  return swipeCounter + 1 === displayProperties.length - i ? (
                    // SWIPE
                    <TinderCard
                      ref={swipeRef}
                      className={tinderCardClassName}
                      key={property.id}
                      onSwipe={(dir) => swiped(dir, property.id)}
                      swipeRequirementType="position"
                      preventSwipe={["up", "down"]}
                      onCardLeftScreen={(dir) => {
                        onCardLeftScreen(property.id, dir)
                      }}
                    >
                      {/* .card */}
                      <PropertyCard
                        setModalState={setModalState}
                        property={property}
                        // className="w-[250px]"
                        openMapModalFunc={() => {
                          setModalState("map")
                        }}
                        // className={`mt-[${5}px]`}
                      />
                    </TinderCard>
                  ) : (
                    <TinderCard
                      className={tinderCardClassName}
                      key={property.id}
                      onSwipe={(direction) => swiped(direction, property.id)}
                      swipeRequirementType="position"
                      onCardLeftScreen={(dir) => {
                        onCardLeftScreen(property.id, dir)
                      }}
                      preventSwipe={["up", "down"]}
                    >
                      <PropertyCard
                        setModalState={setModalState}
                        property={property}
                        openMapModalFunc={() => {
                          setModalState("map")
                        }}
                      />
                    </TinderCard>
                  )
                })}
              </div>
            </div>
            {displayProperties.length > 0 &&
              displayProperties[topCardIndex] && (
                <div className="flex space-x-8">
                  <div className="flex  space-x-4">
                    <div>
                      <CircleButton
                        className={"px-4 py-4"}
                        onClick={() => {
                          shareProperty(
                            displayProperties[topCardIndex].locationKey,
                            displayProperties[topCardIndex].id
                          )
                          setShowToast(true)
                          setTimeout(() => setShowToast(false), 2000)
                        }}
                      >
                        <ShareIcon />
                      </CircleButton>
                    </div>
                    <div>
                      <CircleButton
                        className={"px-6 py-6"}
                        onClick={() => swipe("left")}
                      >
                        <CrossIcon
                          width="36px"
                          height="36px"
                          className="fill-red-500"
                        />
                      </CircleButton>{" "}
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div>
                      <CircleButton
                        className={"px-6 py-6"}
                        onClick={() => swipe("right")}
                      >
                        <HeartIcon
                          width="36px"
                          height="36px"
                          className="fill-green-500"
                        />
                      </CircleButton>
                    </div>
                    <div>
                      {/* link to property */}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.rightmove.co.uk${displayProperties[topCardIndex].propertyUrl}`}
                      >
                        <CircleButton className={"px-4 py-4"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                            />
                          </svg>
                        </CircleButton>
                      </a>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>

        <Toast showToast={showToast} />
        <Modal
          onClose={() => setModalState("")}
          modalState={modalState}
          setModalState={setModalState}
          property={displayProperties[topCardIndex]}
        />
      </Div100vh>
    </>
  )
}

export default PropertySwiper
