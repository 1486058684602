import { getLocalStorage, storeLocalStorage } from "../utils/localStorageHelper"

function CardTopButtons({ property, refreshData, openMapModalFunc }) {
  function deleteLikedProperty() {
    const likedPropertiesFromLocal = getLocalStorage("likedProperties")

    const newPropertyList = likedPropertiesFromLocal.filter(
      (p) => p !== property.id
    )

    storeLocalStorage("likedProperties", newPropertyList)

    refreshData()
  }

  return (
    <div className="absolute left-0 top-0 z-30 w-full flex justify-between">
      {refreshData ? (
        <button
          className="text-3xl text-red-500 pl-2 z-30 font-bold hover:text-red-600"
          onClick={() => deleteLikedProperty()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      ) : (
        <div className="flex-1"></div>
      )}
      <div>
        <div
          className={`mr-4 mt-4 hover:bg-opacity-40 flex items-center justify-center flex-col focus:outline-none cursor-pointer bg-black bg-opacity-30 rounded-full p-2`}
          onClick={openMapModalFunc}
        >
          <span
            className={`focus:outline-none text-white
             inline-flex items-center justify-center`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 3c2.131 0 4 1.73 4 3.702 0 2.05-1.714 4.941-4 8.561-2.286-3.62-4-6.511-4-8.561 0-1.972 1.869-3.702 4-3.702zm0-2c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z" />
            </svg>
            <span className="sr-only">Map</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default CardTopButtons
