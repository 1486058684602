/* eslint-disable react/prop-types */
import classnames from "classnames"
import FeaturesModalBody from "./FeaturesModalBody"
import FloorplanModalBody from "./FloorplanModalBody"
import GuessModalBody from "./GuessModalBody"
import MapModalBody from "./MapModalBody"
import ResultModalBody from "./ResultModalBody"
import PlayedModalBody from "./PlayedModalBody"

const ModalHeader = ({ title, onClose }) => {
  return (
    <div className="flex items-start justify-between p-4 border-b rounded-t  bg-gradient-to-b from-[#FD297B] via-[#FF5864] to-[#FF655B]">
      <h3 className="text-2xl text-gray-100 font-semibold font-['Montserrat']">
        {title}
      </h3>
      {onClose && (
        <button
          onClick={onClose}
          type="button"
          className="text-gray-100 bg-opacity-0 bg-red-500 hover:hover:bg-opacity-50 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          data-modal-toggle="defaultModal"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      )}
    </div>
  )
}

export default function Modal({ modalState, onClose, property }) {
  const notCloseable = modalState === "guess" || modalState === "result"
  const renderBody = () => {
    {
      if (modalState === "map") {
        return (
          <>
            <ModalHeader title="Map" onClose={onClose} />
            <div className="p-4 space-y-6">
              <MapModalBody
                coords={{
                  lat: property.location.latitude,
                  long: property.location.longitude,
                }}
              />
            </div>
          </>
        )
      }
    }
  }

  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className={classnames(
        "fixed top-0 left-0 right-0 z-[10000] w-full overflow-x-hidden overflow-y-auto inset-0 h-full",
        { hidden: !modalState }
      )}
    >
      <div
        className="relative w-full h-full  flex items-center px-6 bg-black/40 "
        onClick={onClose}
      >
        <div className="m-auto flex w-full max-w-lg items-center">
          <div
            className="relative bg-white rounded-lg shadow w-full"
            onClick={(ev) => ev.stopPropagation()}
          >
            {renderBody()}
          </div>
        </div>
      </div>
    </div>
  )
}
