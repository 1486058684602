export function getLocalStorage(key) {
  return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
}

export function storeLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function appendLocalStorage(key, value) {
  const curr = getLocalStorage(key)
  storeLocalStorage(key, [...curr, ...value])
}

export function appendOverwriteLocalStorage(key, value) {
  const curr = getLocalStorage(key)
  if (curr.includes(value)) return
  storeLocalStorage(key, [...curr, value])
}
