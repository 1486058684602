import { useSearchParams } from "react-router-dom"
import PropertySwiper from "./PropertySwiper"
import TutorialSwiper from "./TutorialSwiper"

function SwiperSwitcher() {
  const [searchParams, setSearchParams] = useSearchParams()
  const start = searchParams.get("start")

  const isNotFirstRun = !!localStorage.getItem("firstRun")
  return isNotFirstRun || start ? <PropertySwiper /> : <TutorialSwiper />
}

export default SwiperSwitcher
