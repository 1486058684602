import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const HomeRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate("/swipe")
  })

  return null
}

export default HomeRedirect
