// import EmptyHouseSvg from "../assets/EmptyHouse"
import { Link } from "react-router-dom"
import GradientButton from "./Button/GradientButton"
export default function SearchBlankState() {
  return (
    <div>
      <div className="flex justify-center items-center ">
        <div
          type="button"
          className="relative  w-2/3 border-2 border-red-300 border-dashed rounded-lg p-12 text-center hover:border-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-12 flex flex-col justify-center items-center"
        >
          {/* <EmptyHouseSvg /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12   text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>

          <span className="mt-2 block text-sm font-medium text-gray-900">
            No more properties matching your criteria
          </span>
          <Link to="/search" className="">
            <GradientButton title="Search" />
          </Link>
        </div>
      </div>
    </div>
  )
}
