import React from "react"

function Qrcode() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 1160 1160"
      xmlSpace="preserve"
      className="fill-black"
    >
      <path
        fill="currentColor"
        className="fill-gray-50"
        d="M0 0H1160V1160H0z"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 0)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 0)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 0)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 0)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 0)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 0)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 40)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 520 40)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 40)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 40)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 520 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 120)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 120)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 520 120)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 120)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 160)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 160)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 200)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 200)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 200)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 200)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 240)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 240)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 240)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 240)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 240)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 280)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 280)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 280)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 280)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 280)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 0 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 80 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 240 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 280 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 880 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 320)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 0 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 40 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 120 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 160 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 200 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 280 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 520 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 840 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 920 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 360)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 200 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 240 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 840 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 880 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 400)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 80 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 120 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 160 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 840 440)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 0 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 40 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 160 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 200 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 240 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 480)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 160 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 520 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 920 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 520)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 0 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 40 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 120 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 160 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 240 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 280 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 520 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 840 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 880 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 560)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 80 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 160 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 200 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 280 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 840 600)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 0 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 40 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 80 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 120 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 200 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 240 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 920 640)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 680)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 680)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 680)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 680)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 680)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 680)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 520 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 720)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 400 760)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 760)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 760)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 760)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 760)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 760)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 760)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 920 800)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 840)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 840)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 600 840)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 840)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 840)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 880 840)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 920 840)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 840 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 920 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 880)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 920)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 440 920)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 920)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 920)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 920)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 760 920)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 800 920)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 320 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 360 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 480 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 560 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 640 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 680 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 720 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 840 960)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(.4 0 0 .4 960 960)"
      ></path>
      <g transform="translate(80 80) scale(2.8)">
        <path fill="none" d="M15 15H85V85H15z"></path>
        <path d="M85 0H0v100h100V0H85zm0 85H15V15h70v70z"></path>
      </g>
      <g transform="translate(80 80) translate(720) scale(2.8)">
        <path fill="none" d="M15 15H85V85H15z"></path>
        <path d="M85 0H0v100h100V0H85zm0 85H15V15h70v70z"></path>
      </g>
      <g transform="translate(80 80) matrix(2.8 0 0 2.8 0 720)">
        <path fill="none" d="M15 15H85V85H15z"></path>
        <path d="M85 0H0v100h100V0H85zm0 85H15V15h70v70z"></path>
      </g>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(1.2 0 0 1.2 80 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(1.2 0 0 1.2 800 80)"
      ></path>
      <path
        d="M0 0H100V100H0z"
        transform="translate(80 80) matrix(1.2 0 0 1.2 80 800)"
      ></path>
    </svg>
  )
}

export default Qrcode
