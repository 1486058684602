export default function NotFound() {
  return (
    <>
      <main
        className="min-h-screen bg-cover bg-top sm:bg-top"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1645345349016-10bd7f216af5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80")',
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold  text-opacity-50 uppercase tracking-wide  text-gray-300">
            404 error
          </p>
          <h1 className="mt-2 text-4xl font-extrabold  tracking-tight sm:text-5xl     text-gray-300">
            Uh oh! I think youre lost.
          </h1>
          <p className="mt-2 text-lg font-medium  text-opacity-50  text-white">
            It looks like the page youre looking for doesnt exist.
          </p>
          <div className="mt-6">
            <a
              href="#"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
            >
              Go back home
            </a>
          </div>
        </div>
      </main>
    </>
  )
}
