import {
  SearchIcon,
  HeartIcon,
  QuestionMarkCircleIcon,
  MailIcon,
} from "@heroicons/react/outline"
import { Link } from "react-router-dom"
export default function NavMenuItem(props) {
  return (
    <div className="flex-1 md:flex hidden  items-center justify-center">
      <Link
        to={`/${props.href}`}
        className={
          "-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
        }
      >
        <props.icon
          className="flex-shrink-0 h-6 w-6 text-gray-400 hover:animate-pulse"
          aria-hidden="true"
        />
        <span className="ml-3 ">{props.name}</span>
      </Link>
    </div>
  )
}
