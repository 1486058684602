const locations = [
  {
    key: "edinburgh",
    title: "Edinburgh",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^475",
    latitude: 55.9533,
    longitude: -3.1883,
    region: "Scotland",
  },
  {
    key: "aberdeen",
    title: "Aberdeen",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^94487",
    latitude: 57.147,
    longitude: -2.0946,
    region: "Scotland",
  },
  {
    key: "bath",
    title: "Bath",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^116",
    latitude: 51.3811,
    longitude: -2.3595,
    region: "South West England",
  },
  {
    key: "birmingham",
    title: "Birmingham",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^162",
    latitude: 52.4819,
    longitude: -1.9034,
    region: "West Midlands",
  },
  {
    key: "blackpool",
    title: "Blackpool",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^168",
    latitude: 53.816699,
    longitude: -3.037149,
    region: "North West England",
  },
  {
    key: "bradford",
    title: "Bradford",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^198",
    latitude: 53.7938,
    longitude: -1.753,
    region: "Yorkshire and the Humber",
  },
  {
    key: "brighton-and-hove",
    title: "Brighton",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^61480",
    latitude: 50.8214,
    longitude: -0.1416,
    region: "South East England",
  },
  {
    key: "bristol",
    title: "Bristol",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^219",
    latitude: 51.4529,
    longitude: -2.5972,
    region: "South West England",
  },
  {
    key: "cambridge",
    title: "Cambridge",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^274",
    latitude: 52.2053,
    longitude: 0.1191,
    region: "East of England",
  },
  {
    key: "canterbury",
    title: "Canterbury",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^279",
    latitude: 51.2812,
    longitude: 1.0794,
    region: "South East England",
  },
  {
    key: "cardiff",
    title: "Cardiff",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^281",
    latitude: 51.4818,
    longitude: -3.1785,
    region: "Wales",
  },
  {
    key: "carlisle",
    title: "Carlisle",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^283",
    latitude: 54.8979,
    longitude: -2.9361,
    region: "North West England",
  },
  {
    key: "chelmsford",
    title: "Chelmsford",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^307",
    latitude: 51.7347,
    longitude: 0.4717,
    region: "East of England",
  },
  {
    key: "chester",
    title: "Chester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^313",
    latitude: 53.1912,
    longitude: -2.8911,
    region: "North West England",
  },
  {
    key: "chichester",
    title: "Chichester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^316",
    latitude: 50.8366,
    longitude: -0.7792,
    region: "South East England",
  },
  {
    key: "colchester",
    title: "Colchester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^347",
    latitude: 51.8898,
    longitude: 0.8994,
    region: "East of England",
  },
  {
    key: "coventry",
    title: "Coventry",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^368",
    latitude: 52.4083,
    longitude: -1.511,
    region: "West Midlands",
  },
  {
    key: "derby",
    title: "Derby",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^418",
    latitude: 52.9212,
    longitude: -1.4765,
    region: "East Midlands",
  },
  {
    key: "dundee",
    title: "Dundee",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^452",
    latitude: 56.4603,
    longitude: -2.9701,
    region: "Scotland",
  },
  {
    key: "dunfermline",
    title: "Dunfermline",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^453",
    latitude: 56.0717,
    longitude: -3.4615,
    region: "Scotland",
  },
  {
    key: "durham",
    title: "Durham",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^460",
    latitude: 54.7771,
    longitude: -1.5757,
    region: "North East England",
  },
  {
    key: "exeter",
    title: "Exeter",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^494",
    latitude: 50.7252,
    longitude: -3.5273,
    region: "South West England",
  },
  {
    key: "glasgow",
    title: "Glasgow",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION%5E550",
    latitude: 55.8613,
    longitude: -4.2489,
    region: "Scotland",
  },
  {
    key: "gloucester",
    title: "Gloucester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^556",
    latitude: 51.8663,
    longitude: -2.2472,
    region: "South West England",
  },
  {
    key: "harrogate",
    title: "Harrogate",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^598",
    latitude: 53.991974,
    longitude: -1.542146,
    region: "Yorkshire and the Humber",
  },
  {
    key: "hereford",
    title: "Hereford",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^629",
    latitude: 52.053,
    longitude: -2.7236,
    region: "West Midlands",
  },
  {
    key: "inverness",
    title: "Inverness",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^687",
    latitude: 57.4794,
    longitude: -4.2257,
    region: "Scotland",
  },
  {
    key: "kingston-upon-hull",
    title: "Hull",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^665",
    latitude: 53.7439,
    longitude: -0.3395,
    region: "Yorkshire and the Humber",
  },
  {
    key: "lancaster",
    title: "Lancaster",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^769",
    latitude: 54.0437,
    longitude: -2.8015,
    region: "North West England",
  },
  {
    key: "leeds",
    title: "Leeds",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^787",
    latitude: 53.7946,
    longitude: -1.5456,
    region: "Yorkshire and the Humber",
  },
  {
    key: "leicester",
    title: "Leicester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^789",
    latitude: 52.6365,
    longitude: -1.1332,
    region: "East Midlands",
  },
  {
    key: "lichfield",
    title: "Lichfield",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^800",
    latitude: 52.6842,
    longitude: -1.828,
    region: "West Midlands",
  },
  {
    key: "lincoln",
    title: "Lincoln",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^804",
    latitude: 53.2293,
    longitude: -0.5409,
    region: "East Midlands",
  },
  {
    key: "liverpool",
    title: "Liverpool",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^813",
    latitude: 53.4065,
    longitude: -2.9924,
    region: "North West England",
  },
  {
    key: "london",
    title: "London",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^87490",
    latitude: 51.5045,
    longitude: -0.1291,
    region: "London",
  },
  {
    key: "manchester",
    title: "Manchester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^904",
    latitude: 53.4795,
    longitude: -2.2467,
    region: "North West England",
  },
  {
    key: "milton-keynes",
    title: "Milton Keynes",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^940",
    latitude: 52.0405,
    longitude: -0.7598,
    region: "South East England",
  },
  {
    key: "newcastle-upon-tyne",
    title: "Newcastle",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^984",
    latitude: 54.9739,
    longitude: -1.6133,
    region: "North East England",
  },
  {
    key: "newport",
    title: "Newport",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^991",
    latitude: 51.5884,
    longitude: -2.9972,
    region: "Wales",
  },
  {
    key: "norwich",
    title: "Norwich",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1018",
    latitude: 52.6291,
    longitude: 1.2916,
    region: "East of England",
  },
  {
    key: "nottingham",
    title: "Nottingham",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1019",
    latitude: 52.953,
    longitude: -1.1508,
    region: "East Midlands",
  },
  {
    key: "oxford",
    title: "Oxford",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1036",
    latitude: 51.752,
    longitude: -1.2586,
    region: "South East England",
  },
  {
    key: "perth",
    title: "Perth",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1060",
    latitude: 56.3968,
    longitude: -3.4339,
    region: "Scotland",
  },
  {
    key: "peterborough",
    title: "Peterborough",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1061",
    latitude: 52.5734,
    longitude: -0.2431,
    region: "East of England",
  },
  {
    key: "plymouth",
    title: "Plymouth",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1073",
    latitude: 50.3715,
    longitude: -4.1429,
    region: "South West England",
  },
  {
    key: "portsmouth",
    title: "Portsmouth",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1089",
    latitude: 50.8038,
    longitude: -1.0753,
    region: "South East England",
  },
  {
    key: "preston",
    title: "Preston",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1097",
    latitude: 53.7593,
    longitude: -2.6992,
    region: "North West England",
  },
  {
    key: "reading",
    title: "Reading",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1114",
    latitude: 51.455188,
    longitude: -0.979525,
    region: "South East England",
  },
  {
    key: "ripon",
    title: "Ripon",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1132",
    latitude: 54.136,
    longitude: -1.5235,
    region: "Yorkshire and the Humber",
  },
  {
    key: "salisbury",
    title: "Salisbury",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1165",
    latitude: 51.0683,
    longitude: -1.7949,
    region: "South West England",
  },
  {
    key: "sheffield",
    title: "Sheffield",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1195",
    latitude: 53.3796,
    longitude: -1.4701,
    region: "Yorkshire and the Humber",
  },
  {
    key: "southampton",
    title: "Southampton",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1231",
    latitude: 50.9025,
    longitude: -1.404,
    region: "South East England",
  },
  {
    key: "southend-on-sea",
    title: "Southend",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1232",
    latitude: 51.5369,
    longitude: 0.7141,
    region: "East of England",
  },
  {
    key: "st-albans",
    title: "St Albans",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1244",
    latitude: 51.7536,
    longitude: -0.3389,
    region: "East of England",
  },
  {
    key: "stirling",
    title: "Stirling",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1266",
    latitude: 56.1184,
    longitude: -3.9359,
    region: "Scotland",
  },
  {
    key: "stoke-on-trent",
    title: "Stoke",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1271",
    latitude: 53.016,
    longitude: -2.1818,
    region: "West Midlands",
  },
  {
    key: "sunderland",
    title: "Sunderland",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1295",
    latitude: 54.9056,
    longitude: -1.3819,
    region: "North East England",
  },
  {
    key: "swansea",
    title: "Swansea",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1305",
    latitude: 51.6193,
    longitude: -3.9475,
    region: "Wales",
  },
  {
    key: "wakefield",
    title: "Wakefield",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1386",
    latitude: 53.6833,
    longitude: -1.4969,
    region: "Yorkshire and the Humber",
  },
  {
    key: "winchester",
    title: "Winchester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1460",
    latitude: 51.0606,
    longitude: -1.3118,
    region: "South East England",
  },
  {
    key: "wolverhampton",
    title: "Wolverhampton",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1476",
    latitude: 52.5851,
    longitude: -2.1279,
    region: "West Midlands",
  },
  {
    key: "worcester",
    title: "Worcester",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1483",
    latitude: 52.191,
    longitude: -2.2204,
    region: "West Midlands",
  },
  {
    key: "wrexham",
    title: "Wrexham",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1489",
    latitude: 53.0474,
    longitude: -2.9945,
    region: "Wales",
  },
  {
    key: "york",
    title: "York",
    url: "https://www.rightmove.co.uk/property-for-sale/find.html?locationIdentifier=REGION^1498",
    latitude: 53.9601,
    longitude: -1.0822,
    region: "Yorkshire and the Humber",
  },
]

export default locations
