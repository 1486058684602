import React from "react"

function EmptyHouseSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 313.535 313.535"
      version="1.1"
      viewBox="0 0 313.535 313.535"
      xmlSpace="preserve"
      height="50"
      width="50"
      className="fill-red-500"
    >
      <path d="M7.337 177.353c2.714 1.795 5.847 2.687 8.98 2.687s6.266-.897 8.98-2.687a16.191 16.191 0 002.556-2.094l10.639-10.639v74.515c0 32.841 29.028 60.591 63.387 60.591h114.868c33.668 0 61.064-27.184 61.064-60.591v-71.741l7.87 7.87c3.182 3.187 7.365 4.781 11.536 4.781s8.354-1.594 11.536-4.781c6.375-6.369 6.375-16.703 0-23.073L179.843 23.269c-11.928-11.922-30.78-12.526-43.583-2.094-.859.696-1.768 1.3-2.567 2.094L4.781 152.186c-6.375 6.369-6.375 16.703 0 23.073a16.303 16.303 0 002.556 2.094zm237.84-42.599V239.13c0 15.414-12.755 27.957-28.43 27.957H101.879c-16.382.005-30.752-13.059-30.752-27.951v-107.15l85.644-85.638 88.406 88.406z"></path>
    </svg>
  )
}

export default EmptyHouseSvg
