import PropertyCard from "./PropertyCard"
import React, { useEffect, useState } from "react"
import Navbar from "./Navbar"
import { Link, useNavigate } from "react-router-dom"
import Modal from "./Modal/Modal"
import Toast from "./Toast"
import LoadingSpinner from "./LoadingSpinner"
import { getLocalStorage } from "../utils/localStorageHelper"
import EmptyHouseSvg from "../assets/EmptyHouse"
import ChatBubble from "./ChatBubble"
import GradientButton from "./Button/GradientButton"
function LikedProperties() {
  const navigate = useNavigate()
  useEffect(() => {
    const body = document.querySelector("body")
    body.classList.add("overflow-visible")
  }, [])

  const [modalState, setModalState] = useState(null)
  const [selectedProperty, setSelectedProperty] = useState(null)

  const [likedProperties, setLikedProperties] = useState([])
  const [displayProperties, setDisplayProperties] = useState([])
  const [listings, setListings] = useState([])
  const [loading, setLoading] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const refreshData = async () => {
    const likedLocations = getLocalStorage("likedLocations")

    if (likedLocations < 1) {
      setLoading(false)
      return
    }

    const likedLocationsData = await Promise.all(
      likedLocations.map(
        async (l) => (await import(`../data/locations/${l}.json`)).default
      )
    )

    const allLikedLocationsData = likedLocationsData.reduce((a, b) => ({
      ...a,
      ...b,
    }))

    setListings(allLikedLocationsData)

    const likedPropertiesFromLocal = getLocalStorage("likedProperties")

    setLikedProperties(likedPropertiesFromLocal)
    if (displayProperties.length < 1) {
      setDisplayProperties(likedPropertiesFromLocal.slice(0, 6))
      setLoading(false)
    } else {
      setDisplayProperties((sp) => likedPropertiesFromLocal.slice(0, sp.length))
    }
  }

  const loadMore = () => {
    setDisplayProperties((s) => [
      ...s,
      ...likedProperties.slice(s.length, s.length + 6),
    ])
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <div className="w-screen min-h-screen bg-red-50 bg-gradient-to-b from-[#FD297B]/40 via-[#FF5864]/40 to-[#FF655B]/40  pb-8 ">
      <ChatBubble />
      <Navbar className={`sticky`} />

      {displayProperties.length > 0 ? (
        <div className="mt-4 mb-6">
          <p className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl ">
            Favourites
          </p>
        </div>
      ) : (
        <div className="flex justify-center items-center   ">
          {loading ? (
            <div className=" items-center justify-center flex mt-16">
              <LoadingSpinner />
            </div>
          ) : (
            <div
              type="button"
              className="relative  w-2/3 border-2 border-red-300 border-dashed rounded-lg p-12 text-center hover:border-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-12 flex flex-col justify-center items-center"
            >
              <EmptyHouseSvg />

              <span className="mt-2 block text-base font-medium text-gray-900">
                You don&apos;t have any favourites
              </span>

              <Link to="/swipe" className="">
                <GradientButton title="Swipe to add" />
              </Link>
            </div>
          )}
        </div>
      )}
      <div className="w-screen  flex justify-center p-4 ">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {displayProperties.map((id, i) => (
            <div className="mr-2 mb-2" key={id}>
              <PropertyCard
                property={listings[id]}
                showButtons
                openMapModalFunc={() => {
                  setSelectedProperty(listings[id])
                  setModalState("map")
                }}
                refreshData={refreshData}
                setShowToast={setShowToast}
              />
            </div>
          ))}
        </div>
      </div>
      {likedProperties.length !== displayProperties.length ? (
        <div className="flex justify-center mt-14 ">
          {/* <button
            className="text-pink-500 border border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase px-8 py-3 rounded outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 mb-6"
            onClick={loadMore}
          >
            Load More
          </button> */}

          <GradientButton onClick={loadMore} title="Load More" />
        </div>
      ) : likedProperties.length === 0 ? (
        <></>
      ) : (
        <div className="flex flex-col items-center justify-center">
          {" "}
          <p className="text-center pt-4 pb-0">No more favourites</p>{" "}
          <GradientButton
            onClick={() => navigate("/swipe")}
            title="Swipe to add more"
          />
        </div>
      )}
      <Toast showToast={showToast} />
      <Modal
        onClose={() => setModalState("")}
        modalState={modalState}
        setModalState={setModalState}
        property={selectedProperty}
      />
    </div>
  )
}

export default LikedProperties
