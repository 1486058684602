const GradientButton = ({ onClick, title }) => {
  return (
    <button
      type="button"
      className="btn-hover color-4"
      onClick={onClick}
      onTouchStart={onClick}
    >
      {title}
    </button>
  )
}

export default GradientButton
