import { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import TawkMessengerReact from "@tawk.to/tawk-messenger-react"
import { Disclosure } from "@headlessui/react"
import Navbar from "./Navbar"
import ChatBubble from "./ChatBubble"
import GradientButton from "./Button/GradientButton"
function Faq() {
  useEffect(() => {
    const body = document.querySelector("body")
    body.classList.add("overflow-visible")
  }, [])
  const navigate = useNavigate()
  const faqs = [
    {
      question: "What is this?",
      answer: (
        <div>
          Swipemove lets you search for your next home by swiping through
          properties. Swipe right if you&#39;re interested and left if you
          aren&#39;t.
        </div>
      ),
    },
    {
      question: "How to contact you?",
      answer:
        "If you need to contact us please click the widget in the bottom right of the screen. We're happy to hear you ideas and suggestions, bug reports, or answer any questions",
    },
    {
      question: "Who made this?",
      answer: (
        <div>
          {" "}
          SwipeMove was made by{" "}
          <a
            href="https://www.fraserhamilton.dev"
            target="_blank"
            rel="noreferrer"
            className="underline hover:bg-blue-50 cursor-pointer"
          >
            Fraser Hamilton
          </a>{" "}
          and{" "}
          <a
            href="https://mattgordon.xyz"
            target="_blank"
            rel="noreferrer"
            className="underline hover:bg-blue-50 cursor-pointer"
          >
            Matt Gordon
          </a>
        </div>
      ),
    },
    {
      question: "Press",
      answer: (
        <div>
          {" "}
          <p className="text-gray-500 ">
            Please contact us by filling in the form{" "}
            <a
              href="https://tawk.to/chat/6482345ccc26a871b02170f3/1h2e9p2rb"
              className="underline hover:bg-blue-50 cursor-pointer"
            >
              here
            </a>
            . We&apos;ll get back to you straight away.
          </p>
        </div>
      ),
    },
    {
      question: <div>How to use this?</div>,
      answer:
        "Swipe left if you like the property. Swipe right if you don't. Alternatively use the ❌ and ❤️ buttons.",
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  return (
    <div className="scroll-smooth">
      {/* <TawkMessengerReact
        propertyId="6482345ccc26a871b02170f3"
        widgetId="1h2e9p2rb"
      /> */}

      <Navbar className={`sticky`} />

      <div className="">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <div className="text-base text-gray-500">
                          {faq.answer}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* old */}
      <div className="flex items-center justify-center flex-col">
        <GradientButton title="Swipe" onClick={() => navigate("/swipe")} />
        {/* <button
          className="text-pink-500 border border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase px-8 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => navigate("/swipe")}
        >
          Swipe
        </button> */}
        <p className="text-gray-700 ">Find your Perfect Match</p>
      </div>
      <ChatBubble />
    </div>
  )
}
export default Faq
