/* eslint-disable react/prop-types */
import {
  EffectCards,
  EffectCoverflow,
  EffectCreative,
  EffectCube,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
} from "swiper"
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react"
import { lazy, useEffect, useState } from "react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
// import "swiper/css/effect-fade"

import classNames from "classnames"

export default function Carousel({ images, openMapModalFunc }) {
  return (
    <Swiper
      spaceBetween={30}
      effect="fade"
      rewind={true}
      slidesPerView={1}
      navigation={{
        nextEl: ".next",
        prevEl: ".previous",
      }}
      fadeEffect={{
        crossFade: true,
      }}
      allowTouchMove={false}
      modules={[Navigation, Pagination, EffectFade]}
    >
      {images.map((i) => (
        <SwiperSlide key={i}>
          <div className="flex rounded-t-lg justify-center ">
            <img
              className="h-[40vh] w-full rounded-t-lg object-cover pointer-events-none select-none"
              src={i}
              // loading="lazy"
            />
            {/* <div className="swiper-lazy-preloader"></div> */}
          </div>
        </SwiperSlide>
      ))}

      <div
        className={classNames(
          `absolute top-0 z-30 flex items-center justify-center h-full px-4 focus:outline-none left-0`
        )}
      >
        <span
          className={`focus:outline-none text-white
             inline-flex items-center justify-center  cursor-pointer previous`}
        >
          <svg
            aria-hidden="true"
            className="w-18 h-18 lg:w-6 lg:h-6 svg-shadow"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </div>

      <div
        className={classNames(
          `absolute top-0 z-30 flex items-center justify-center flex-col h-full px-4 pr-2 focus:outline-none right-0`
        )}
      >
        <span
          className={`focus:outline-none text-white
             inline-flex items-center justify-center cursor-pointer next`}
        >
          <svg
            aria-hidden="true"
            className="w-18 h-18 lg:w-6 lg:h-6 svg-shadow"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </div>

      {/* <div className="bg-white text-xl h-2 absolute bottom-0 left-0 w-full rounded-t-xl z-10" /> */}
    </Swiper>
  )
}
