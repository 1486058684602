import Navbar from "./Navbar"
import locations from "../data/locations"
import { Fragment, useEffect, useState } from "react"
import { Menu, Transition, Popover } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import StickyList from "./StickyList"
import { groupBy, orderBy } from "lodash"

import { Disclosure } from "@headlessui/react"
import { Link } from "react-router-dom"
import LocationsMapPicker from "./LocationsMapPicker"
import ChatBubble from "./ChatBubble"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Search() {
  const locationsByKey = groupBy(
    orderBy(locations, ["title"], ["asc"]),
    "region"
  )

  const regionOrder = [
    "London",
    "Scotland",
    "North East England",
    "North West England",
    "South East England",
    "South West England",
    "East of England",
    "Yorkshire and the Humber",
    "East Midlands",
    "West Midlands",
    "Wales",
  ]

  const locationsArray = regionOrder.map((rk) => [rk, locationsByKey[rk]])

  console.log("locationsArray", locationsArray)

  const [showMap, setShowMap] = useState(false)

  return (
    <div className="flex justify-center flex-col items-center w-full h-full pt-12 pb-24">
      <Navbar />
      <ChatBubble classNames={"hidden"} />
      <div className="max-w-3xl mx-auto w-full mt-10 px-6  ">
        <ul className="flex text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg shadow sm:flex">
          <li className="w-full">
            <a
              className={`w-full p-4 hover:cursor-pointer bg-white rounded-l-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 flex items-center justify-center ${
                !showMap && "bg-gray-100 text-gray-900"
              }`}
              aria-current="page"
              onClick={() => setShowMap(false)}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              List
            </a>
          </li>
          <li className="w-full  bg-red-50">
            <a
              onClick={() => setShowMap(true)}
              className={`w-full p-4 hover:cursor-pointer bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 flex items-center justify-center ${
                showMap && "bg-gray-100 text-gray-900  "
              }`}
            >
              Map
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                />
              </svg>
            </a>
          </li>
        </ul>
        {!showMap && (
          <p className="text-center mt-6 text-gray-700">
            Find property for sale in
          </p>
        )}

        {showMap ? (
          <LocationsMapPicker />
        ) : (
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {locationsArray.map(([region, values]) => (
              <Disclosure as="div" key={region} className="pt-6 px-12 md:px-0">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900 ">
                          {region}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </span>
                      </Disclosure.Button>
                    </dt>

                    <Disclosure.Panel as="dd" className="mt-2">
                      <ul
                        role="list"
                        className="relative z-0 divide-y divide-gray-200"
                      >
                        {values.map((value) => (
                          <Link
                            key={value.id}
                            to={`/swipe?location=${value.key}`}
                          >
                            <li className="bg-white">
                              <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                                <div className="flex-shrink-0"></div>
                                <div className="flex-1 min-w-0">
                                  <a href="#" className="focus:outline-none">
                                    {/* Extend touch target to entire panel */}
                                    <span
                                      className="absolute inset-0"
                                      aria-hidden="true"
                                    />
                                    <p className="text-sm font-medium text-gray-900">
                                      {value.title}
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        )}
      </div>
    </div>
  )
}
