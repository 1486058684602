import React, { useEffect, useState, useRef } from "react"
import TinderCard from "./ReactTinderCard"
import listings from "./listings.json"
import { ReactComponent as CrossIcon } from "../assets/cross.svg"
import { ReactComponent as HeartIcon } from "../assets/heart.svg"
import { ReactComponent as ShareIcon } from "../assets/share.svg"
import CircleButton from "./Button/CircleButton"
import PropertyCard from "./PropertyCard"
import Navbar from "./Navbar"
import Modal from "./Modal/Modal"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import Div100vh from "react-div-100vh"
import Toast from "./Toast"
import QrcodeInfoBox from "./QrcodeInfoBox"
import Overlay from "./Overlay"
import ChatBubble from "./ChatBubble"
import { shareProperty } from "../utils/share"
import SearchBlankState from "./SearchBlankState"
import LoadingSpinner from "./LoadingSpinner"
import Lottie from "lottie-react"
import swipeRightLottie from "../assets/swipeRightLottie.json"
import swipeLeftLottie from "../assets/swipeLeftLottie.json"
import searchLottie from "../assets/searchLottie.json"
import houseLottie from "../assets/houselottie.json"
import GradientButton from "./Button/GradientButton"
import {
  appendOverwriteLocalStorage,
  getLocalStorage,
  storeLocalStorage,
} from "../utils/localStorageHelper"
import CardTopButtons from "./CardTopButtons"
import Carousel from "./Carousel"

const IconInfoGroup = ({ Icon, info }) => (
  <div className="flex flex-col space-y-1 items-center">
    <Icon width="24px" height="24px" />
    <p className="font-semibold">{info}</p>
  </div>
)

function TutorialSwiper() {
  const [searchParams, setSearchParams] = useSearchParams()
  const start = searchParams.get("start")

  const [rawPropertyData, setRawPropertyData] = useState([])
  const [swipeCounter, setSwipeCounter] = useState(0)
  const [modalState, setModalState] = useState(null)

  const [swipedId, setSwipedId] = useState(null)

  const [showToast, setShowToast] = useState(false)
  const [displayProperties, setDisplayProperties] = useState([])

  const [hasInitialized, setHasInitialized] = useState(false)

  const swipeRef = useRef([])

  const setup = async () => {
    const location = "tutorial"

    const rawImportedPropertyData = (
      await import(`../data/locations/tutorial.json`)
    ).default

    const rawPropertyDataGuarded =
      rawPropertyData.length < 1
        ? Object.keys(rawImportedPropertyData).map(
            (k) => rawImportedPropertyData[k]
          )
        : rawPropertyData

    if (displayProperties.length > 1) setSwipeCounter((sc) => sc + 1)

    if (displayProperties.length > 0) {
      // if (rawPropertyDataGuarded[swipeCounter + 5]) {
      //   console.log("hello", rawPropertyDataGuarded)
      //   setDisplayProperties((p) => [
      //     rawPropertyDataGuarded[swipeCounter + 5],
      //     ...p,
      //   ])
      // }
    } else {
      const seenPropertiesFromLocal = getLocalStorage("seenProperties")

      const propertyDataWithoutSeen = rawPropertyDataGuarded

      const start = searchParams.get("start")

      const startProperty = rawImportedPropertyData[start]
      console.log("START", startProperty)

      if (startProperty) {
        console.log("CODE PATH")
        const propertyDataWithoutSeenAndStart = propertyDataWithoutSeen.filter(
          (p) => p.id !== startProperty.id
        )

        setRawPropertyData([startProperty, ...propertyDataWithoutSeenAndStart])
        setDisplayProperties([
          ...propertyDataWithoutSeenAndStart.slice(0, 4),
          startProperty,
        ])
      } else {
        setDisplayProperties(propertyDataWithoutSeen.slice(0, 5))
        setRawPropertyData(propertyDataWithoutSeen)
      }

      setHasInitialized(true)
    }
  }

  useEffect(() => {
    setup()
  }, [swipedId])

  const swiped = (direction, id) => {
    setSwipedId((prevId) => (prevId !== id ? id : prevId))
  }

  const onCardLeftScreen = (id, dir) => {}

  const swipe = async (dir, index = 0) => {
    console.log("swipeRef", swipeRef)
    await swipeRef.current[index].swipe(dir) // Swipe the card!
  }

  const cardWidth = "w-[80vw] md:w-[40vw] lg:w-[35vw] xl:w-[30vw] 2xl:w-[20vw]"

  const tinderCardClassName = `absolute ${cardWidth}`

  const topCardIndex = displayProperties.length - 1 - swipeCounter

  const navigate = useNavigate()

  return (
    <>
      <ChatBubble classNames="hidden" />
      <QrcodeInfoBox />
      <Div100vh>
        <div className=" w-screen h-full overflow-hidden  bg-white ">
          {/* <div className=" w-screen h-full overflow-hidden  bg-white "> */}
          {/* <div className=" w-screen h-full overflow-hidden  bg-repeat bg-[url('../public/bg-houses.png')] bg-gray-300 bg-blend-multiply "> */}
          root
          <div className="w-full h-full pb-16 xl:pb-20 bg-red-50 bg-gradient-to-b from-[#FD297B]/40 via-[#FF5864]/40 to-[#FF655B]/40  flex-col flex items-center justify-center  ">
            {/* <Navbar /> */}

            <div className="scroll-smooth absolute top-0 left-0 w-full z-40 ">
              <nav className="flex flex-1 justify-evenly md:justify-center items-center w-full py-3 md:py-4  bg-white">
                {/* md: */}

                <div className=" rounded-xl flex flex-1 items-center justify-center">
                  <h1 className=" md:text-3xl tracking-tighter whitespace-nowrap  font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 font-['Montserrat']   text-center ">
                    swipemove ⌂
                  </h1>
                </div>
              </nav>
            </div>
            <div className="flex-1 flex-col flex items-center justify-center w-full  ">
              {/* .cardcontainer */}

              {/* {console.log("dp", displayProperties)} */}
              {!hasInitialized && (
                // <p className="animate-spin text-white text-5xl">🏠</p>
                <LoadingSpinner />
              )}
              {hasInitialized &&
                (displayProperties.length <= swipeCounter ||
                  rawPropertyData.length < 1) && (
                  <>
                    <SearchBlankState />
                  </>
                )}
              <div
                className={`bg-orange-500 relative ${cardWidth} max-h-[450px] flex items-center justify-center`}
              >
                {displayProperties.length > 0 && (
                  <>
                    <TinderCard
                      ref={(el) => (swipeRef.current[3] = el)}
                      className={tinderCardClassName}
                      key={displayProperties[2].id}
                      onSwipe={(dir) => swiped(dir, displayProperties[2].id)}
                      swipeRequirementType="position"
                      preventSwipe={["up", "down", "left", "right"]}
                      onCardLeftScreen={(dir) => {
                        onCardLeftScreen(displayProperties[2].id, dir)
                      }}
                    >
                      <TutorialCard property={displayProperties[2]} empty>
                        <div className="flex flex-col items-center justify-center">
                          <Lottie
                            animationData={searchLottie}
                            loop={true}
                            className=" h-24 w-24 md:h-48 md:w-48"
                          />{" "}
                          <h1 className="text-white font-bold text-3xl md:text-3xl  text-center px-4 ">
                            Match with your ideal home
                          </h1>
                          <div className=" md:pt-4 pb-4 ">
                            <p className=" text-gray-200 text-center mt-2 px-4   ">
                              Our algorithm learns from your preferences to help
                              find your match. Choose an area to get started{" "}
                            </p>
                          </div>
                          <div className="flex  justify-items align-items mt-0">
                            <Link to={`/search`}>
                              {/* <button
                                onClick={() =>
                                  storeLocalStorage("firstRun", "true")
                                }
                                className="bg-gradient-to-r hover:bg-gradient-to-bl  flex from-pink-500 via-red-500 to-yellow-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-16 mb-4"
                              >
                                🔍 Search
                              </button> */}
                              <GradientButton
                                title="🔍 Search"
                                onClick={() => {
                                  storeLocalStorage("firstRun", "true")
                                  navigate("/search")
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                      </TutorialCard>

                      {/* .card */}
                    </TinderCard>
                    <TinderCard
                      ref={(el) => (swipeRef.current[2] = el)}
                      className={tinderCardClassName}
                      key={displayProperties[3].id}
                      onSwipe={(dir) => swiped(dir, displayProperties[3].id)}
                      swipeRequirementType="position"
                      preventSwipe={["up", "down"]}
                      onCardLeftScreen={(dir) => {
                        onCardLeftScreen(displayProperties[3].id, dir)
                      }}
                    >
                      <TutorialCard property={displayProperties[3]}>
                        <div className="flex flex-col items-center justify-center">
                          <Lottie
                            animationData={swipeLeftLottie}
                            loop={true}
                            className=" h-24 w-24 md:h-48 md:w-48"
                          />
                          <h1 className="text-white font-bold text-3xl  text-center ">
                            <span className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 bg-[length:100%_5px] bg-no-repeat bg-bottom ">
                              Swipe left to pass
                            </span>
                          </h1>
                          <h2 className="text-white bg-black px-2 py-1 font-semibold text-xl  text-center mt-12 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500  ">
                            or press the{" "}
                            <CrossIcon className="bg-white rounded-full h-8 w-8 p-2 inline fill-red-500 " />{" "}
                            button
                          </h2>
                        </div>
                      </TutorialCard>
                    </TinderCard>
                    <TinderCard
                      ref={(el) => (swipeRef.current[1] = el)}
                      className={tinderCardClassName}
                      key={displayProperties[0].id}
                      onSwipe={(dir) => swiped(dir, displayProperties[0].id)}
                      swipeRequirementType="position"
                      preventSwipe={["up", "down"]}
                      onCardLeftScreen={(dir) => {
                        onCardLeftScreen(displayProperties[0].id, dir)
                      }}
                    >
                      <TutorialCard property={displayProperties[0]}>
                        <div className="flex flex-col items-center justify-center">
                          <Lottie
                            animationData={swipeRightLottie}
                            loop={true}
                            className=" h-24 w-24 md:h-48 md:w-48 "
                          />

                          <h1 className="text-white font-bold text-xl md:text-3xl  text-center">
                            <span className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 bg-[length:100%_5px] bg-no-repeat bg-bottom  ">
                              Swipe right if you like the property
                            </span>
                          </h1>
                          <h2 className="text-white font-semibold text-xl  text-center mt-12 bg-black py-1 px-2">
                            or press the{" "}
                            <HeartIcon className="bg-white rounded-full h-8 w-8 p-2 inline fill-green-500" />{" "}
                            button
                          </h2>
                        </div>
                      </TutorialCard>

                      {/* .card */}
                    </TinderCard>
                    <TinderCard
                      ref={(el) => (swipeRef.current[0] = el)}
                      className={tinderCardClassName}
                      key={displayProperties[0].id}
                      onSwipe={(dir) => swiped(dir, displayProperties[1].id)}
                      swipeRequirementType="position"
                      preventSwipe={["up", "down"]}
                      onCardLeftScreen={(dir) => {
                        onCardLeftScreen(displayProperties[1].id, dir)
                      }}
                    >
                      <TutorialCard property={displayProperties[1]} empty>
                        <>
                          <Lottie
                            animationData={houseLottie}
                            loop={false}
                            className=" h-24 w-24 md:h-48 md:w-48 "
                          />{" "}
                          <h1 className="text-white font-bold text-xl md:text-3xl text-center  px-4  ">
                            Match with your Perfect Property
                          </h1>
                          <p className=" text-gray-200 text-center mt-4 px-4 ">
                            Swipe to find your perfect match
                          </p>
                          <div className="flex flex-col justify-items align-items mt-16">
                            {/* <button
                              className="bg-gradient-to-r hover:bg-gradient-to-l from-pink-500 via-red-500 to-yellow-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-16 mb-4"
                              onClick={() => swipe("right", 0)}
                            >
                              Start Tutorial
                            </button> */}
                            <GradientButton
                              title="Start Tutorial"
                              // onTouchStart={() => console.log("foo")}
                              onClick={() => swipe("right", 0)}
                            />
                            <button
                              onClick={() => {
                                storeLocalStorage("firstRun", "true")
                                navigate("/search")
                              }}
                              onTouchStart={() => {
                                storeLocalStorage("firstRun", "true")
                                navigate("/search")
                              }}
                              type="button"
                              className="underline text-gray-300 font-semibold  px-4 rounded-full cursor-pointer"
                            >
                              Skip
                            </button>
                          </div>
                        </>
                      </TutorialCard>

                      {/* .card */}
                    </TinderCard>
                  </>
                )}
              </div>
            </div>
            {displayProperties.length > 0 && swipeCounter < 3 && (
              <div className="flex space-x-8">
                <div className="flex  space-x-4">
                  <div>
                    <CircleButton
                      className={"px-4 py-4 invisible"}
                      onClick={() => {
                        shareProperty(
                          displayProperties[topCardIndex].locationKey,
                          displayProperties[topCardIndex].id
                        )
                        setShowToast(true)
                        setTimeout(() => setShowToast(false), 2000)
                      }}
                    >
                      <ShareIcon />
                    </CircleButton>
                  </div>
                  <div>
                    <CircleButton
                      className={`px-6 py-6 ${
                        swipeCounter > 1 ? "visible" : "invisible"
                      }`}
                      onClick={() => swipe("left", swipeCounter)}
                    >
                      <CrossIcon
                        width="36px"
                        height="36px"
                        className="fill-red-500"
                      />
                    </CircleButton>{" "}
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div>
                    <CircleButton
                      className={`px-6 py-6 ${
                        swipeCounter > 0 && swipeCounter !== 2
                          ? "visible"
                          : "invisible"
                      }`}
                      onClick={() => swipe("right", swipeCounter)}
                    >
                      <HeartIcon
                        width="36px"
                        height="36px"
                        className="fill-green-500"
                      />
                    </CircleButton>
                  </div>
                  <div>
                    {/* link to property */}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.rightmove.co.uk${displayProperties[topCardIndex].propertyUrl}`}
                    >
                      <CircleButton className={"px-4 py-4 invisible"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </svg>
                      </CircleButton>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Toast showToast={showToast} />
        <Modal
          onClose={() => setModalState("")}
          modalState={modalState}
          setModalState={setModalState}
          property={displayProperties[topCardIndex]}
        />
      </Div100vh>
    </>
  )
}

const TutorialCard = ({ property, empty, children }) => {
  return (
    <div className="relative">
      <div className="bg-black bg-opacity-50 absolute top-0 left-0 z-[1000] w-full h-full rounded-lg flex items-center justify-center flex-col">
        {children}
      </div>
      <div className={`rounded-lg overflow-hidden bg-white shadow`}>
        <div className="">
          <div className="relative">
            <CardTopButtons property={property} openMapModalFunc={() => {}} />

            <Carousel images={property.images} />
          </div>
          <div className="bg-white p-2  select-none">
            <p
              className={`text-gray-700 font-light text-sm leading-tight font-['Montserrat'] ${
                empty && "invisible"
              }`}
            >
              Offers over
            </p>

            <h1 className="font-semibold text-2xl md:text-2xl">
              <span
                className={`leading-6 text-blue-700 ${empty && "invisible"}`}
              >
                £{parseInt(property.price).toLocaleString("en-UK")}
              </span>
            </h1>

            <div className="flex items-baseline pt-2 select-none text-gray-800 ">
              <div className="flex  justify-between w-full py2">
                <div
                  className={`flex align-items justify-left space-x-2 font-medium w-full ${
                    empty && "invisible"
                  }`}
                >
                  <p className="">{property.propertySubType}</p>
                  <p className="">{property.bedrooms} beds</p>
                  <p className="">{property.bathrooms} baths</p>
                </div>
              </div>
            </div>
            <h2
              className={`pt-1 pb-1 text-gray-800 text-sm min-h-[3.4em] ${
                empty && "invisible"
              } `}
            >
              {property.displayAddress}
            </h2>
          </div>
          {/* Buttons for liked properties */}

          {false && (
            <div className="flex items-center justify-around bg-50 pt-2 pb-2">
              <div>
                {/* link to property */}
                <div>
                  {/* map */}

                  <button
                    onClick={() => {
                      shareProperty(property.locationKey, property.id)
                      setShowToast(true)
                      setTimeout(() => setShowToast(false), 2000)
                    }}
                    type="button"
                    className="cursor-pointer py-2.5 flex flex-col px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700  items-center"
                  >
                    <ShareIcon />
                    <span className="text-xs text-gray-800 pt-1">Share</span>
                  </button>
                </div>
              </div>
              <div>
                {/* link to property */}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.rightmove.co.uk${property.propertyUrl}`}
                >
                  <button
                    disabled
                    type="button"
                    className="cursor-pointer  flex flex-col py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700  items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>

                    <span className="text-xs text-gray-800 pt-1">
                      View Listing
                    </span>
                  </button>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TutorialSwiper

// 1 IMG = woman finding dream house welcome
// swipe right to start

// 2 IMG mansion  swipe right if you like it left if you dont

// 3 dont worry we learn from your preferences

// 4 you can use the buttons [btns appear]

// 5 see on map and share [btns appear]

// 6 search
