import { useState } from "react"
import Qrcode from "./Qrcode"
export default function QrCodeInfoBox() {
  const [openQrModel, setOpenQrModal] = useState(true)

  return (
    openQrModel && (
      <div className="bg-gray-50 shadow-2xl sm:rounded-lg z-50 top-10 right-5 hidden lg:flex  absolute">
        <div onClick={() => setOpenQrModal(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 absolute top-2 right-2 text-red-500 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="mt-2 max-w-xl text-sm text-gray-700 flex flex-col justify-center items-center text-center  ">
            🤳
            <p className=" w-24 tracking-tight mb-2 ">
              SwipeMove works better on mobile.
            </p>
            <Qrcode className="bg-gray-50" />
            <p className=" w-24  tracking-tight mt-2">
              Scan the QR code with your phone
            </p>
          </div>
        </div>
      </div>
    )
  )
}
