import { Link, useNavigate } from "react-router-dom"
import locations from "../data/locations"
import { MapContainer } from "react-leaflet"
import LocationMap from "./LocationMap"
import { useEffect, useState } from "react"
import BlueButton from "./Button/BlueButton"
import GameHeader from "./GameHeader"
import ReactResizeDetector from "react-resize-detector"
import GradientButton from "./Button/GradientButton"
import day from "../data/day"

export default function LocationsMapPicker() {
  const navigate = useNavigate()

  const [selectedLocation, setSelectedLocation] = useState({
    key: "london",
    title: "London",
    url: "https://www.rightmove.co.uk/house-prices/london-87490.html?country=england&searchLocation=London",
    latitude: 51.5045,
    longitude: -0.1291,
  })

  const onSubmit = () => {
    navigate(`/swipe?location=${selectedLocation?.key}`)
  }
  useEffect(() => {
    // Applying on mount
    document.body.style.overflow = "hidden"
    // Applying on unmount
    return () => {
      document.body.style.overflow = "visible"
    }
  }, [])

  return (
    <div className="w-full absolute left-0 h-screen z-[-1]" id="test">
      {/* <div className="m-0 mx-auto max-w-lg flex flex-col bg-[url('../public/bg-houses.png')]  bg-center h-full"> */}
      <div className="m-0 mx-auto max-w-lg flex flex-col bg-center h-full">
        <div className="px-4 py-4 flex flex-col items-center">
          <h1 className="text-3xl mb-4 font-['Montserrat'] font-semibold text-black">
            {selectedLocation?.title}
          </h1>
          <div>
            {/* <Link to={`/play?location=${selectedLocation?.key}`}> */}

            <GradientButton title="Select Location" onClick={onSubmit} />
            {/* <button
              className="text-xs text-pink-500 border border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase px-8 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex items-center justify-center"
              type="button"
              onClick={onSubmit}
            >
              Select Location{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
            </button> */}
            {/* </Link> */}
          </div>
        </div>
        <ReactResizeDetector handleHeight>
          {({ height }) => (
            <div className="h-full">
              {height && (
                <div style={{ height: height }} className={`bg-white`}>
                  <MapContainer
                    center={[54, -2.2]}
                    zoom={6}
                    scrollWheelZoom={true}
                    className="h-full"
                  >
                    <LocationMap
                      locations={locations}
                      setSelectedLocation={setSelectedLocation}
                      selectedLocation={selectedLocation}
                      day={day}
                    />
                  </MapContainer>
                </div>
              )}
            </div>
          )}
        </ReactResizeDetector>
      </div>
    </div>
  )
}
