import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { ReactComponent as FaqIcon } from "../assets/faq.svg"
import NavMenuItem from "./NavMenuItem"
import {
  SearchIcon,
  HeartIcon,
  QuestionMarkCircleIcon,
  MailIcon,
} from "@heroicons/react/outline"

import { Link } from "react-router-dom"
const callsToAction = [
  { name: "Search", href: "search", icon: SearchIcon },
  { name: "Favs", href: "liked", icon: HeartIcon },
  { name: "Faq", href: "faq", icon: QuestionMarkCircleIcon },
]
import FlyoutMenu from "./FlyoutMenu"
function Navbar({ className }) {
  const navigate = useNavigate()
  const location = useLocation()

  const isSwipe = location.pathname.includes("swipe")
  const isFaq = location.pathname.includes("faq")
  return (
    <div
      className={`scroll-smooth absolute top-0 left-0 w-full z-40 ${className}`}
    >
      <nav className="flex flex-1 justify-evenly md:justify-center items-center w-full py-3 md:py-4  bg-white">
        <div className="flex flex-1 md:hidden">
          <FlyoutMenu className=" " />
        </div>
        <button
          onClick={() => navigate("/swipe")}
          className=" rounded-xl md:hidden flex flex-1 items-center justify-center"
        >
          <h1 className=" md:text-3xl tracking-tighter whitespace-nowrap  font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 font-['Montserrat']   text-center ">
            swipemove ⌂
          </h1>
        </button>
        <div className="flex-1 flex  md:hidden  items-center justify-center">
          <Link
            to={`/search`}
            className={
              "-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-800 hover:bg-gray-100 transition ease-in-out duration-150"
            }
          >
            <SearchIcon
              className="flex-shrink-0 h-6 w-6 text-gray-400"
              aria-hidden="true"
            />
            <span className="ml-3 text-sm">Search</span>
          </Link>
        </div>

        {/* md: */}

        <div
          key={"contact"}
          className="flex-1 md:flex hidden  items-center justify-center"
        >
          <a
            href={"https://tawk.to/chat/6482345ccc26a871b02170f3/1h2e9p2rb"}
            target="_blank"
            rel="noreferrer"
            className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
          >
            <MailIcon
              className="flex-shrink-0 h-6 w-6 text-gray-400 "
              aria-hidden="true"
            />
            <span className="ml-3 ">Contact</span>
          </a>
        </div>

        <NavMenuItem name="Faq" href="faq" icon={QuestionMarkCircleIcon} />

        <button
          onClick={() => navigate("/swipe")}
          className=" rounded-xl hidden md:flex flex-1 items-center justify-center  "
        >
          <h1 className=" md:text-3xl tracking-tighter whitespace-nowrap  font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 font-['Montserrat']   text-center ">
            swipemove ⌂
          </h1>
        </button>
        <NavMenuItem name="Favs" href="liked" icon={HeartIcon} />

        <NavMenuItem name="Search" href="search" icon={SearchIcon} />
      </nav>
    </div>
  )
}

export default Navbar
