/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import {
  SearchIcon,
  HeartIcon,
  QuestionMarkCircleIcon,
  MailIcon,
} from "@heroicons/react/outline"
import { ReactComponent as CrossIcon } from "../assets/cross.svg"
import { Link } from "react-router-dom"
import Lottie from "lottie-react"
import hamburgerLottie from "../assets/hamburgerLottie.json"
const callsToAction = [
  { name: "Search", href: "search", icon: SearchIcon },
  { name: "Favs", href: "liked", icon: HeartIcon },
  { name: "Faq", href: "faq", icon: QuestionMarkCircleIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function FlyoutMenu() {
  return (
    <Popover className="z-0 relative">
      {({ open }) => (
        <>
          <div className="relative z-9000 bg-white  ">
            <div className="max-w-7xl mx-auto flex px-4  sm:px-6 lg:px-8">
              <Popover.Button
                className={classNames(
                  open ? "text-gray-900" : "text-gray-500",
                  "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:hidden"
                )}
              >
                {open ? (
                  <p className="px-2 text-red-700 font-bold font-[montserrat]">
                    X
                  </p>
                ) : (
                  <span>
                    {/* <MenuIcon /> */}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  </span>
                )}
              </Popover.Button>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute z-[9000] inset-x-0 inset-y-8 transform shadow-lg w-screen h-screen">
              <div className="bg-white"></div>
              <div className="bg-gray-50 h-full">
                <div className="max-w-7xl mx-auto space-y-6 px-4 py-5 lg:px-8">
                  {callsToAction.map((item) => (
                    <div key={item.name} className="flow-root">
                      <Link
                        to={`/${item.href}`}
                        className={
                          "-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150 border-b-2 py-8 "
                        }
                      >
                        <item.icon
                          className="flex-shrink-0 h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-3 ">{item.name}</span>
                      </Link>
                      {/* <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" /> */}
                    </div>
                  ))}
                  <div key={"contact"} className="flow-root ">
                    <a
                      href={
                        "https://tawk.to/chat/6482345ccc26a871b02170f3/1h2e9p2rb"
                      }
                      className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150 border-b-2 py-8"
                    >
                      <MailIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3 ">Contact</span>
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
