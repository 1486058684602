import { Routes, Route, redirect } from "react-router-dom"
import Home from "./components/Home"
import LikedProperties from "./components/LikedProperties"
import Faq from "./components/Faq"
import Search from "./components/Search"
import NotFound from "./components/NotFound"
import SwiperSwitcher from "./components/SwiperSwitcher"
import HomeRedirect from "./components/HomeRedirect"
function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeRedirect />} />
      <Route path="/search" element={<Search />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/liked" element={<LikedProperties />} />
      <Route path="/swipe" element={<SwiperSwitcher />} />
      {/* <Route path="/swipe" element={<TestSwiper />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
