import classNames from "classnames"

const CircleButton = ({ onClick, disabled, children, className }) => (
  <button
    onClick={onClick}
    type="button"
    className={classNames(
      `text-gray-900 flex-1 flex items-center justify-center bg-white border-gray-100 focus:outline-none focus:ring-4
      focus:ring-gray-200 font-medium rounded-full text-sm  drop-shadow-lg ${className} `,
      {
        "cursor-not-allowed": disabled,
        "hover:bg-gray-100": !disabled,
      }
    )}
    disabled={disabled}
  >
    <div className={classNames({ "opacity-20": disabled })}>{children}</div>
  </button>
)

export default CircleButton
