import React from "react"

import Carousel from "../components/Carousel"
import { ReactComponent as ShareIcon } from "../assets/share.svg"
import CardTopButtons from "./CardTopButtons"
import { shareProperty } from "../utils/share"
function PropertyCard({
  property,
  showButtons,
  className = "",
  openMapModalFunc,
  refreshData,
  setShowToast,
}) {
  return (
    <div className={`rounded-lg overflow-hidden bg-white shadow  ${className}`}>
      <div className="">
        <div className="relative">
          <CardTopButtons
            property={property}
            refreshData={refreshData}
            openMapModalFunc={openMapModalFunc}
          />

          <Carousel images={property.images} />
        </div>
        <div className="bg-white p-2  select-none">
          <p className="text-gray-700 font-light text-sm leading-tight font-['Montserrat'] ">
            Offers over
          </p>
          <h1 className="font-semibold text-2xl md:text-2xl">
            <span className="leading-6 text-blue-700 ">
              £{parseInt(property.price).toLocaleString("en-UK")}
            </span>
          </h1>

          <div className="flex items-baseline pt-2 select-none text-gray-800 ">
            <div className="flex  justify-between w-full py2">
              <div className="flex align-items  justify-left space-x-2 font-medium w-full">
                <p className="">{property.propertySubType}</p>
                <p className="">{property.bedrooms} beds</p>
                <p className="">{property.bathrooms} baths</p>
              </div>
            </div>
          </div>
          <h2 className="pt-1 pb-1 text-gray-800 text-sm min-h-[3.4em] line-clamp-2">
            {property.displayAddress}
          </h2>
        </div>
        {/* Buttons for liked properties */}

        {showButtons && (
          <div className="flex items-center justify-around bg-50 pt-2 pb-2">
            <div>
              {/* link to property */}
              <div>
                {/* map */}

                <button
                  onClick={() => {
                    shareProperty(property.locationKey, property.id)
                    setShowToast(true)
                    setTimeout(() => setShowToast(false), 2000)
                  }}
                  type="button"
                  className="cursor-pointer py-2.5 flex flex-col px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700  items-center"
                >
                  <ShareIcon />
                  <span className="text-xs text-gray-800 pt-1">Share</span>
                </button>
              </div>
            </div>
            <div>
              {/* link to property */}
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.rightmove.co.uk${property.propertyUrl}`}
              >
                <button
                  disabled
                  type="button"
                  className="cursor-pointer  flex flex-col py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700  items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="text-gray-800 "
                    fill="currentColor"
                  >
                    <path d="M13 16h-2v-6h2v6zm-1-10.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25zm0-2.75c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006z" />
                  </svg> */}
                  <span className="text-xs text-gray-800 pt-1">
                    View Listing
                  </span>
                </button>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PropertyCard
